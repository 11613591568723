import {cn} from "~/component/ui/lib/utils";
import {ReactNode} from "react";

export const HeroTitleEmphasis = ({ children, className, }: { children :ReactNode; className?: string; }) => (
  <span className={cn("block text-5xl md:text-7xl bg-clip-text", className)}>
    {children}
  </span>
);

export const HeroTitle = ({ children, variant }: { children: ReactNode; variant: "dark" | "light" }) => (
  <h1
    className={cn("block text-4xl md:text-6xl italic", {
      "text-neutral-100": variant === "light",
      "text-neutral-900": variant === "dark",
    })}
  >
    {children}
  </h1>
);

export const Hero = (
  {
    title,
    subtitle,
    img,
    variant,
  }: {
    title: ReactNode,
    subtitle: ReactNode,
    img: string,
    variant: "primary" | "secondary-light" | "gray",
  }) => (
    <section
      className={cn("flex flex-1 md:px-6", {
        "bg-primary": variant === "primary",
        "bg-secondary-background": variant === "secondary-light",
        "bg-[#144f68]": variant === "gray",
      })}
    >
      <div className="container grid lg:grid-cols-2 place-items-center py-12 md:py-32 gap-10">
        <div className="text-center lg:text-start space-y-6">
          <main className="uppercase font-bold">
            {title}
          </main>

          <div
            className={cn("flex flex-col md:flex-row-reverse gap-y-3 md:gap-y-0 md:gap-x-4 justify-center",
              "lg:justify-end text-22px md:text-[24px] text-neutral-800", {
                "text-neutral-100": variant === "primary" || variant === "gray",
                "text-neutral-900": variant === "secondary-light",
              })}
          >
            {subtitle}
          </div>
        </div>

        {/* Hero cards sections */}
        <div className="z-10 justify-center hidden md:flex">
          <img src={img} alt="" className={"w-auto max-w-[60%] h-full"}/>
        </div>

        {/* Shadow effect */}
        <div className="shadow"></div>
      </div>
    </section>
    );
